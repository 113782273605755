import React, { useEffect, useState } from 'react'
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styles from '../Modal.module.css'
// import OrderAddTable from '../Table/AddTable/OrderAddTable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaTrash } from "react-icons/fa";
import axios from 'axios';
// import dayjs from 'dayjs';

const EditOrderModal = ({ closeModal, id }) => {
  const [isPay, setIsPay] = useState(false);
  const [down, setDown] = useState(false);
  const [countryside, setCountryside] = useState(false);
  const [inputs, setInputs] = useState([{ name: "", unit: 0, price: 0, unitPrice: 0 }]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [items, setItems] = useState([]);
  const [shops, setShops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [shop, setShop] = useState('');
  const [discount, setDiscount] = useState('');

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  // const calculateTotalBalance = ({ name }) => {
  //   return items
  //     .filter((e) => e.title.includes(name))
  //     .reduce((total, items) => {
  //       return (
  //         total +
  //         items.balance
  //           .filter((el) => el.status === true)
  //           .reduce((itemTotal, item) => itemTotal + item.unit, 0)
  //       );
  //     }, 0);
  // };

  // const calculateTotalDelivered = ({ name }) => {
  //   return items.filter((e) => e.title.includes(name)).reduce((total, items) => {
  //     return total + items.delivered.reduce((itemTotal, item) => itemTotal + item.unit, 0);
  //   }, 0);
  // };

  const handleChange = (event, index) => {
    let { value, name } = event.target;
    let onChangeValue = [...inputs];

    if (name === "name") {
      // Update the name property
      onChangeValue[index][name] = value;

      // Find the selected item and update price and unitPrice
      const selectedRow = items.find((row) => row.title === value);
      if (selectedRow) {
        onChangeValue[index].price = selectedRow.price;
        onChangeValue[index].unitPrice = selectedRow.price * (onChangeValue[index].unit || 1);
      }
    } else if (name === "unit") {
      // Update the unit property
      onChangeValue[index][name] = value;

      // Update unitPrice based on the updated unit and price
      onChangeValue[index].unitPrice = onChangeValue[index].price * value;
    } else {
      // For other properties, update directly
      onChangeValue[index][name] = value;
    }

    setInputs(onChangeValue);
  };


  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { name: "", unit: 0, price: 0, unitPrice: 0 }]);
  };

  useEffect(() => {
    // Calculate the total protein when the id state changes
    if (!isLoading) {
      const sum = inputs.reduce((acc, item, index) => {
        const selectedRow = items.find((row) => row.title === item.name);
        const unitValue = inputs[index]?.unit || 1; // Access the correct unit value for the current row
        return acc + (selectedRow ? selectedRow.price * unitValue : 0);
      }, 0);


      setTotalPrice(sum - discount);
    }

    if (isLoading) {
      axios.get('https://turboapi.templateapi.xyz/post?user=' + userInfo._id)
        .then((e) => {
          setItems(e.data);
          setIsLoading(false);
        })
        .catch((e) => console.log(e))
      axios.get('https://turboapi.templateapi.xyz/shop?user =' + userInfo._id)
        .then((e) => {
          setShops(e.data.data);
          // setIsLoading(false);
        })
        .catch((e) => console.log(e));
      axios.get('https://turboapi.templateapi.xyz/order/' + id)
        .then((e) => {
          setAddress(e.data.data.address);
          setPhone(e.data.data.phone);
          setShop(e.data.data.shop);
          setDiscount(e.data.data.discount);
          setDown(e.data.data.download);
          setDown(e.data.data.isBill);
          setTotalPrice(e.data.data.price);
          setInputs(e.data.data.order);
        })
        .catch((e) => alert(e))
    }

  }, [inputs, discount]);

  const addOrder = () => {
    axios.put('https://turboapi.templateapi.xyz/order/' + id, {
      address: address,
      phone: phone,
      shop: shop,
      discount: discount,
      download: down,
      countryside: countryside,
      isBill: isPay,
      order: inputs,
      user: userInfo._id,
      price: totalPrice,
      // orderDate: Date.now()
    }).then(() => window.location.reload(false))
      .catch((e) => alert(e));
  }


  return (
    <div className='blur_container'>
      {isLoading ? <div className="loader"></div> :
        <div className='modal' style={{ width: '65vw', marginTop: '50px' }}>
          <div className='two-line' style={{ width: '65vw' }}>
            <h2>Захиалга нэмэх</h2>
            <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
          </div>
          <br />
          <label>
            Харилцагчийн хаяг:
            <textarea name="" id="" value={address} onChange={(e) => setAddress(e.target.value)} cols="40" rows="5"></textarea>
          </label>
          <label>
            Харилцагчийн утас:
            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </label>
          <label>
            Дэлгүүр сонгох:
            {/* <input type="text" /> */}
            <select onChange={(e) => setShop(e.target.value)}>
              <option value=''>{shop}</option>
              {shops.map((e) => (
                <option key={e.id} value={e.name}>{e.name}</option>
              ))}
            </select>
          </label>
          <label>
            Хөнгөлөх дүн:
            <input onChange={(e) => setDiscount(e.target.value)} value={discount} type="text" name="" id="" />
          </label>
          <div className={styles.checkbox_container}>
            <FormControlLabel value={isPay} checked={isPay} onChange={() => setIsPay(!isPay)} control={<Checkbox />} label="Төлбөр төлсөн эсэх" />
            <FormControlLabel value={down} checked={down} onChange={() => setDown(!down)} control={<Checkbox />} label="Татан авалттай эсэх" />
            <FormControlLabel value={countryside} checked={countryside} onChange={() => setCountryside(!countryside)} control={<Checkbox />} label="Хөдөө орон нутаг" />
          </div>
          <button onClick={() => handleAddInput()} className='button-white'>Мөр Нэмэх</button>
          {/* <OrderAddTable unit_arrey={inputs} /> */}
          <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
            <Table sx={{ width: '65vw', maxWidth: '65vw' }} aria-label="simple table">
              <p>Total Price: {totalPrice}</p>
              <TableHead>
                <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                  <TableCell style={{ color: '#fff', fontSize: 16 }}>Бараа</TableCell>
                  {/* <TableCell style={{ color: '#fff', fontSize: 16}}>Дэлгүүр</TableCell> */}
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Нэгж үнэ</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үлдэгдэл</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Тоо</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Нийт дүн</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inputs.map((row, index) => (
                  <TableRow
                    className='hover'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      <select name="name" id="" onChange={(e) => handleChange(e, index)}>
                        <option value=''>{row.name}</option>
                        {items.map((e) => (
                          <option key={e.id} value={e.title}>{e.title}</option>
                        ))}
                      </select>
                    </TableCell>
                    {/* <TableCell align="left">
                    <select name="shop" id="" onChange={(e) => handleChange(e, index)}>
                      {shops.map((e) => (
                        <option key={e.id} value={e.name}>{e.name}</option>
                      ))}
                    </select>
                  </TableCell> */}
                    <TableCell align="center">
                      {items.map((e) => (
                        e.title && inputs[index]?.name === e.title ? (
                          <p onChange={(e) => handleChange(e, index)} name='price' key={e.id} value={e.price}>{e.price}</p>
                        ) : null
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      {/* {items.map((e) => (
                        e.title && inputs[index]?.name === e.title ? (
                          <option key={e.id} value={calculateTotalBalance({ name: e.title }) - calculateTotalDelivered({ name: e.title })}>
                            {calculateTotalBalance({ name: e.title }) - calculateTotalDelivered({ name: e.title })}
                          </option>
                        ) : null
                      ))} */}
                    </TableCell>
                    <TableCell align="center">
                      <input type="number" value={row.unit} onChange={(e) => handleChange(e, index)} name="unit" id="" />
                    </TableCell>
                    <TableCell align="center">
                      {items.map((e) => (
                        e.title && inputs[index]?.name === e.title ? (
                          <option onChange={(e) => handleChange(e, index)} name='unitPrice' key={e.id} value={e.price * (inputs[index]?.unit || 1)}>
                            {parseInt(e.price) * (inputs[index]?.unit || 1)}
                          </option>
                        ) : null
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      <FaTrash onClick={handleDeleteInput} size={30} color='red' />
                      {/* {row.name} */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className='button_container'>
            <button onClick={addOrder} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
            <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
          </div>
        </div>}
    </div>
  )
}

export default EditOrderModal
