import React, { useEffect, useState } from 'react'
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styles from './Modal.module.css'
// import OrderAddTable from '../Table/AddTable/OrderAddTable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaTrash } from "react-icons/fa";
import axios from 'axios';
import dayjs from 'dayjs';
import UbData from '../../Data/UbState';

const OrderAddModal = ({ closeModal, order, setOrder }) => {
  const [isPay, setIsPay] = useState(false);
  const [down, setDown] = useState(false);
  const [countryside, setCountryside] = useState(false);
  const [inputs, setInputs] = useState([{ name: "", unit: 0, price: 0, unitPrice: 0, totalBalance: 0 }]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [items, setItems] = useState([]);
  const [shops, setShops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [shop, setShop] = useState('');
  const [discount, setDiscount] = useState('');
  const [report, setReports] = useState([]);
  const [orders, setOrders] = useState(order);
  const [lastUnit, setLastUnit] = useState([]);
  const [totalUnit, setTotalUnit] = useState([]);
  const [container, setContainer] = useState([]);
  const [areaContainers, setAreaContainers] = useState([]);
  const [areaContainer, setAreaContainer] = useState('');
  const [loader, setLoader] = useState(false);
  const [area, setArea] = useState('');
  const [areasecond, setAreaSecond] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [states, setStates] = useState([]);

  const ubData = UbData();

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  const calculateTotalDownloadBalance = ({ name }) => {
    // alert(userInfo._id);
    return container
      // .filter((e) => areaContainer === e.areacontainer)
      .filter((e) => e.canceled === false)
      .reduce((total, report) => {
        return (
          total +
          report.item
            .filter((e) => e.name === name)
            .filter((el) => el.status === 'Хүлээж авсан')
            .filter((el) => el.switchsuccess === true)
            .reduce(
              (itemTotal, item) =>
                itemTotal + item.unit,
              0
            )
        );
      }, 0);
  };

  const calculateTotalOrdersBalance = ({ name }) => {
    return orders
      // .filter((e) => areaContainer === e.container)
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal + item.unit, 0);
      }, 0);
  };

  const calculateTotalCancleled = ({ name }) => {
    return orders
      // .filter((e) => areaContainer === e.container)
      .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal +
            (
              (
                (dayjs(report.orderDate).add(16, 'hours').isBefore(dayjs().add(8, 'hour')) ||
                  dayjs(report.orderDate).add(16, 'hours').isSame(dayjs().add(8, 'hour'), 'date'))
              ) ?
                item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalRemoved = ({ name }) => {
    return container
      // .filter((e) => areaContainer === e.areacontainer)
      .reduce((total, report) => {
        return total + report
          .item
          .filter((el) => el.status === 'Зарлага')
          .filter((e) => {
            return e.name === name
          })
          .filter((el) => el.switchsuccess === true)
          .reduce((itemTotal, item) => itemTotal +
            (dayjs(report.date).isBefore(dayjs().add(8, 'hour')) ||
              dayjs(report.date).isSame(dayjs().add(8, 'hour'), 'date')
              ? item.unit : 0), 0);
      }, 0);
  };

  const handleChange = (event, index) => {
    let { value, name } = event.target;
    let onChangeValue = [...inputs];

    if (name === "name") {
      onChangeValue[index][name] = value;

      const selectedRow = items.find((row) => row.title === value);
      if (selectedRow) {
        onChangeValue[index].price = selectedRow.price;
        onChangeValue[index].unitPrice = selectedRow.price * (onChangeValue[index].unit || 1);

        onChangeValue[index].totalBalance =
          selectedRow.defaultbalance ? selectedRow.defaultbalance : 0 + (
            calculateTotalDownloadBalance({ name: selectedRow.title }) -
            calculateTotalOrdersBalance({ name: selectedRow.title }) +
            calculateTotalCancleled({ name: selectedRow.title }) -
            calculateTotalRemoved({ name: selectedRow.title })
          )
          ;
      }
    } else if (name === "unit") {
      onChangeValue[index][name] = value;

      onChangeValue[index].unitPrice = onChangeValue[index].price * value;
    } else {
      onChangeValue[index][name] = value;
    }

    setInputs(onChangeValue);
  };


  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { name: "", unit: 0, price: 0, unitPrice: 0, totalUnit: 0 }]);
  };

  useEffect(() => {
    if (!isLoading) {
      console.log(inputs);
      const sum = inputs.reduce((acc, item, index) => {
        const selectedRow = items.find((row) => row.title === item.name);
        const unitValue = inputs[index]?.unit || 1;
        return acc + (selectedRow ? selectedRow.price * unitValue : 0);
      }, 0);


      setTotalPrice(sum - discount);

      const lastItemBalance = inputs.filter((e) => parseInt(e.unit) < 1);

      setLastUnit(lastItemBalance);

      const totalCalcBalance = inputs.filter((e) => parseInt(e.totalBalance) < 1);

      setTotalUnit(totalCalcBalance);
    }

    if (isLoading) {
      axios.get('https://turboapi.templateapi.xyz/post?user=' + userInfo._id)
        .then((e) => {
          setItems(e.data);
          // setIsLoading(false);
        })
        .catch((e) => console.log(e))

      axios.get('https://turboapi.templateapi.xyz/container?user=' + userInfo._id)
        .then((e) => {
          setContainer(e.data.data);
          setIsLoading(false);
        })
        .catch((e) => console.log(e));

      axios.get('https://turboapi.templateapi.xyz/state')
        .then((e) => {
          setStates(e.data.data);
        })
        .catch((e) => console.log(e))


      axios.get('https://turboapi.templateapi.xyz/areacontainer')
        .then((e) => {
          setAreaContainers(e.data.data);
        })
        .catch((e) => console.log(e))
    }

  }, [inputs, discount, isLoading]);

  const handleAddOrder = () => {
    if (!createLoading) {
      setCreateLoading(true);
      addOrder();
    }
  }

  const addOrder = () => {
    setLoader(true);
    axios.post('https://turboapi.templateapi.xyz/order', {
      address: address,
      phone: phone,
      shop: userInfo.shop,
      discount: discount,
      download: down,
      countryside: countryside,
      container: areaContainer,
      isBill: isPay,
      order: inputs,
      user: userInfo._id,
      price: totalPrice,
      // areasecond: areasecond,
      area: area,
      orderDate: dayjs().add(8, 'hour'),
      purchaseDate: dayjs().add(8, 'hour').format('YYYY-MM-DD')
    }).then((e) => {
      const responseOrders = [...order, e.data.data];
      setOrder(responseOrders);
      closeModal();
    })
      .catch((e) => {
        alert(e);
        setCreateLoading(false);
      });
  }


  return (
    <div className='blur_container'>
      {isLoading || createLoading || loader ? <div className="loader"></div> :
        <div className='modal' style={{ width: '65vw', marginTop: '50px' }}>
          <div className='two-line' style={{ width: '65vw' }}>
            <h2>Захиалга нэмэх</h2>
            <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
          </div>
          <br />
          <label>
            Дүүргийн нэр:
            <select onChange={(e) => setState(e.target.value)} value={state}>
              <option value="">Сонгох</option>
              {Object.keys(ubData).map((districtName) => (
                <option key={districtName} value={districtName}>
                  {districtName}
                </option>
              ))}
            </select>
          </label>
          <label>
            Хороо:
            <select onChange={(e) => setDistrict(e.target.value)} value={district}>
              <option value="">Сонгох</option>
              {state && ubData[state].map((subDistrict) => (
                <option key={subDistrict} value={subDistrict}>
                  {subDistrict}
                </option>
              ))}
            </select>
          </label>
          <label>
            Бүс:
            <select onChange={(e) => setArea(e.target.value)}>
              <option value="">Сонгох</option>
              {state === 'Орон нутаг' ?
                <option value="Орон нутаг">Орон нутаг</option>
                : null
              }
              {states.map((stateitem) => (
                stateitem.areas
                  .filter((e) => e.state === state)
                  .map((area) => (
                    area.districts
                      .filter((e) => e.district === district)
                      .map(() => (
                        <option value={stateitem.name}>{stateitem.name}</option>
                      ))
                  ))
              ))}
            </select>
          </label>
          <label>
            Харилцагчийн хаяг:
            <textarea name="" id="" onChange={(e) => setAddress(e.target.value)} cols="40" rows="5"></textarea>
          </label>
          <label>
            Харилцагчийн утас:
            <input type="text" onChange={(e) => setPhone(e.target.value)} />
          </label>
          <label>
            Дэлгүүр сонгох:
            {/* <input type="text" /> */}
            <select onChange={(e) => setShop(e.target.value)}>
              <option value={userInfo.shop}>{userInfo.shop}</option>
              {/* {shops.filter((e) => e.shop).map((e) => (
                <option key={e.id} value={e.shop}>{e.shop}</option>
              ))} */}
              {/* <option key={userInfo.id} value={userInfo.shop}>{userInfo.shop}</option> */}

            </select>
          </label>
          {/* <label>
            Бүс сонгох:
            <select value={areasecond} onChange={(e) => setAreaSecond(e.target.value)}>
              <option value="">Бүгд</option>
              <option value="Баруун">Баруун бүс</option>
              <option value="Зүүн">Зүүн бүс</option>
              <option value="Хойд">Хойд бүс</option>
              <option value="Урд">Урд бүс</option>
              <option value="Төв">Төв бүс</option>
              <option value="Орон нутаг">Орон нутаг</option>
            </select>
          </label> */}
          <label>
            Агуулах сонгох:
            <select onChange={(e) => setAreaContainer(e.target.value)}>
              {areaContainers.map((e) => (
                <option key={e.id} value={e._id}>{e.name}</option>
              ))}
            </select>
          </label>
          <label>
            Хөнгөлөх дүн:
            <input onChange={(e) => setDiscount(e.target.value)} type="text" name="" id="" />
          </label>
          <div className={styles.checkbox_container}>
            <FormControlLabel onChange={() => setIsPay(!isPay)} control={<Checkbox />} label="Төлбөр төлсөн эсэх" />
            <FormControlLabel onChange={() => setDown(!down)} control={<Checkbox />} label="Татан авалттай эсэх" />
            <FormControlLabel onChange={() => setCountryside(!countryside)} control={<Checkbox />} label="Хөдөө орон нутаг" />
          </div>
          <button onClick={() => handleAddInput()} className='button-white'>Мөр Нэмэх</button>
          {/* <OrderAddTable unit_arrey={inputs} /> */}
          <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
            <Table sx={{ width: '65vw', maxWidth: '65vw' }} aria-label="simple table">
              <p>Total Price: {totalPrice}</p>
              <TableHead>
                <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                  <TableCell style={{ color: '#fff', fontSize: 16 }}>Бараа</TableCell>
                  {/* <TableCell style={{ color: '#fff', fontSize: 16}}>Дэлгүүр</TableCell> */}
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Нэгж үнэ</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үлдэгдэл</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Тоо</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Нийт дүн</TableCell>
                  <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inputs.map((row, index) => (
                  <TableRow
                    className='hover'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      <select name="name" id="" onChange={(e) => handleChange(e, index)}>
                        <option value=''>Сонгох</option>
                        {items.map((e) => (
                          <option key={e.id} value={e.title}>{
                            e.secondtitle ? e.secondtitle : e.title
                          }</option>
                        ))}
                      </select>
                    </TableCell>
                    <TableCell align="center">
                      {items.map((e) => (
                        e.title && inputs[index]?.name === e.title ? (
                          <p onChange={(e) => handleChange(e, index)} name='price' key={e.id} value={e.price}>{e.price}</p>
                        ) : null
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      {items.map((e) => (
                        e.title && inputs[index]?.name === e.title ? (
                          <p onChange={(e) => handleChange(e, index)} name='totalBalance' key={e.id}
                            value={e.balance}
                          >
                            {
                              (e.defaultbalance ? e.defaultbalance : 0) +
                              (
                                calculateTotalDownloadBalance({ name: e.title }) -
                                calculateTotalOrdersBalance({ name: e.title }) +
                                calculateTotalCancleled({ name: e.title }) -
                                calculateTotalRemoved({ name: e.title })
                              )
                            }
                          </p>
                        ) : null
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      <input type="number" value={inputs[index]?.unit} onChange={(e) => handleChange(e, index)} name="unit" id="" />
                    </TableCell>
                    <TableCell align="center">
                      {items.map((e) => (
                        e.title && inputs[index]?.name === e.title ? (
                          <option onChange={(e) => handleChange(e, index)} name='unitPrice' key={e.id} value={e.price * (inputs[index]?.unit || 1)}>
                            {parseInt(e.price) * (inputs[index]?.unit || 1)}
                          </option>
                        ) : null
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      <FaTrash onClick={handleDeleteInput} size={30} color='red' />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className='button_container'>

            <button className='button-white' style={{ color: '#fff', background: '#d3d3d3' }}>
              Хадгалах
            </button>
            {/* {area && state && ((lastUnit.length === 0 && totalUnit.length === 0) &&
              inputs[inputs.length - 1]?.unit <= inputs[inputs.length - 1]?.totalBalance && createLoading === false) ?
              <button onClick={handleAddOrder} className='button-white' style={{ color: '#2c9961' }}>
                Хадгалах
              </button> :
              <button className='button-white' style={{ color: '#fff', background: '#d3d3d3' }}>
                Хадгалах
              </button>
            } */}
            <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
          </div>
          {area && state ? null : <p style={{ fontWeight: 'bold' }} className='red-border'>
            Та бүсээ сонгоно уу !!
          </p>}
          {lastUnit.length === 0 && totalUnit.length === 0
            ? null
            : <p style={{ fontWeight: 'bold' }} className='red-border'>Үлдэгдэл 0 байж болохгүй</p>}

          {inputs[inputs.length - 1]?.unit <= inputs[inputs.length - 1]?.totalBalance ?
            null :
            <p style={{ fontWeight: 'bold' }} className='red-border'>Барааны үлдэгдэлээс их бараагаар захиалга хийж болохгүй</p>
          }
        </div>}
    </div>
  )
}

export default OrderAddModal
