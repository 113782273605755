import React, { useEffect, useState } from 'react'
import styles from './Shops.module.css';
import Navbar from '../../Components/Navbar/Navbar';
import ShopsTable from '../../Utills/Table/ShopsTable';
import ShopsAddModal from '../../Utills/Modal/ShopsAddModal';
import axios from 'axios';
import add_icon from '../../Assets/uttils/add_button.svg'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Shops = () => {
  const [isModal, setModal] = useState(false);
  const [load, setLoad] = useState(true);
  const [shops, setShops] = useState([]);
  const [error, setError] = useState(false);

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  const closeModal = () => {
    setModal(false);
  }

  useEffect(() => {
    window.localStorage.setItem('side-id', 6);

    if (load) {
      axios.get('https://turboapi.templateapi.xyz/user?_id=' + userInfo._id)
        .then((e) => {
          setShops(e.data.data);
          setLoad(false)
        })
        .catch((e) => console.log(e));
    }
  }, [load]);

  const handleClose = () => {
    setError(false);
  }

  return (
    <>
      {!isModal ? <Navbar title={'Дэлгүүр'} /> : <></>}
      {isModal ? <ShopsAddModal closeModal={closeModal} /> : <></>}
      {load ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>

        <Snackbar className={styles.snackbar} open={error}
          autoHideDuration={10000} onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert
            onClose={handleClose}
            severity="error"
            // variant="filled"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row', // Display in a row
              alignItems: 'center', // Center items vertically
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            iconMapping={{ vertical: 'top', horizontal: 'right' }}
            className={styles.alert}
          >
            Зөвхөн нэг дэлгүүр нэмэх боломжтой.
          </Alert>
        </Snackbar>
        <div className="space-between" style={{ justifyContent: 'space-between', paddingRight: '2vw' }}>
          {shops.filter((e) => e.shop).length === 0 ?
            <button onClick={() => setModal(true)} className='button'>
              <img src={add_icon} className='icon' alt="" /> Нэмэх
            </button>
            :
            <button style={{ background: '#838383' }} onClick={() => setError(true)} className='button'>
              <img src={add_icon} className='icon' alt="" /> Нэмэх
            </button>
          }
          {/* <button onClick={() => setModal(true)} className='button'>
            <img src={add_icon} className='icon' alt="" /> Нэмэх
          </button> */}
        </div>
        <ShopsTable shops={shops.filter((e) => e.shop)} />
      </div>
    </>
  )
}

export default Shops
