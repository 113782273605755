import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.css'
import Navbar from '../../Components/Navbar/Navbar'
import axios from 'axios';
import dayjs from 'dayjs';

import { Link } from 'react-router-dom';

const Dashboard = ({ orders }) => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [orders, setOrders] = useState([]);

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  // useEffect(() => {
  //   if (isLoading) {
  //     window.localStorage.setItem('side-id', 0);
  //     axios.get('https://turboapi.templateapi.xyz/order?sort=-createdAt&user=' + userInfo._id)
  //       .then((e) => { setOrders(e.data.data); setIsLoading(false) })
  //       .catch((e) => console.log(e));
  //   }
  // }, [isLoading]);

  return (
    <>
      {/* {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>} */}
      <Navbar title={'Хянах самбар'} />


      <div className={styles.container}>
        <Link to={'/orderdetial/Хүлээгдэж байна'} className='dashboard-card'>
          <p>Шинэ захиалга</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => e.status.includes('Хүлээгдэж байна') &&
              dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Жолооч авсан'} className='dashboard-card'>
          <p>Жолооч хүлээн авсан</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => e.status.includes('Жолооч авсан')
              && dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Буцаагдсан'} className='dashboard-card'>
          <p>Авахаа больсон</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => e.status.includes('Буцаагдсан') &&
              dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Хүргэсэн'} className='dashboard-card'>
          <p>Хүргэсэн</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => (e.status.includes('Хүргэсэн')
              || e.status.includes('Хэсэгчлэн хүргэсэн')) &&
              dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Хойшлуулсан'} className='dashboard-card'>
          <p>Хойшилсон</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => (e.status.includes('Хойшлуулсан') || e.status.includes('Маргааш авах'))
              && dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Хаягаар очсон'} className='dashboard-card'>
          <p>Хаягаар очсон</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => e.status.includes('Хаягаар очсон')
              && dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')).length}
          </h3>
        </Link>
      </div>
    </>
  )
}

export default Dashboard
