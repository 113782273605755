import axios from 'axios';
import React, { useState } from 'react'

const CategoryAddModal = ({ closeModal }) => {
  const [name, setName] = useState('');

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  const addCategory = () => {
    axios.post('https://turboapi.templateapi.xyz/categories', {
      name: name,
      user: userInfo._id
    })
      .then(() => window.location.reload(false))
      .catch((e) => e)
  }
  return (
    <div className='blur_container'>
      <div className='modal'>
        <div className='two-line'>
          <h2>Барааны бүлэг нэмэх</h2>
          <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
        </div>
        <label>
          Бүлэг нэр:
          <input type="text" onChange={(e) => setName(e.target.value)} value={name} />
        </label>
        <div className='button_container'>
          <button onClick={addCategory} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
          <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
        </div>
      </div>
    </div>
  )
}

export default CategoryAddModal
