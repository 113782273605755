import React, { useEffect, useState } from 'react'
import styles from './Order.module.css'
// import OrderTable from '../../Utills/Table/OrderTable';
import Navbar from '../../Components/Navbar/Navbar';
import OrderAddModal from '../../Utills/Modal/OrderAddModal';
import axios from 'axios';
import OrderTable from '../../Utills/Table/OrderTable';
import EditOrderModal from '../../Utills/Modal/EditModal/EditOrderModal';
import add_icon from '../../Assets/uttils/add_button.svg'
import ExportExcel from '../../excelexport';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

const OrderFiltered = ({ orders }) => {
    const [isModal, setIsModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editId, setEditId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    // const [orders, setOrders] = useState([]);
    const [drivers, setDriver] = useState([]);
    const [excelData, setExcelData] = useState([{ утас: '', хаяг: '', төлөв: '', 'нийт дүн': '', огноо: '', жолооч: '' }])
    const { id } = useParams();

    const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

    const closeModal = () => {
        setIsModal(false);
        setEditModal(false);
    }

    useEffect(() => {
        window.localStorage.setItem('side-id', 1);

        if (isLoading) {


            axios.get('https://turboapi.templateapi.xyz/user?sort=-createdAt&role=driver')
                .then((e) => {
                    setIsLoading(false);
                    setDriver(e.data.data);
                })
                .catch((e) => console.log(e));

            // axios.get('https://turboapi.templateapi.xyz/order?sort=-createdAt&user=' + userInfo._id)
            //     .then((order) => {
            //         setOrders(order.data.data);
            //         setIsLoading(false);
            //     })
            //     .catch((e) => console.log(e));
        }
        if (!isLoading) {

            const exceldata =
                orders.map((el) => ({
                    утас: el.phone,
                    хаяг: el.address,
                    төлөв: el.status,
                    'нийт дүн': new Intl.NumberFormat('en-Us').format(el.price),
                    огноо: dayjs(el.createdAt).format('YYYY-MM-DD'),
                    жолооч: drivers.find((catchdriver) => catchdriver._id === el.driver)?.name,
                }));
            setExcelData(exceldata);
        }


    }, [isLoading])

    const edit = ({ id }) => {
        setEditId(id);
        setEditModal(true);
    }

    return (
        <>
            {!isModal ? <Navbar title={'Захиалга'} /> : <></>}
            {isModal ? <div className='abs'><OrderAddModal closeModal={closeModal} /></div> : <></>}
            {editModal ? <div className='abs'><EditOrderModal id={editId} closeModal={closeModal} /></div> : <></>}
            {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
            <div className={styles.container}>
                <div className='line-start'>
                    <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
                    {/* <button className='button-white'>See Item</button> */}
                    {/* <button className='button-white'>Print</button> */}
                    <ExportExcel fileName={'name'} excelData={excelData} />
                </div>
                <br />
                <div>
                    {/* <OrderItemTable orders={orders}/> */}
                    <OrderTable orders={orders
                        .filter((e) => dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD'))
                        .filter((e) => id === 'Хүргэсэн' ?
                            e.status === 'Хэсэгчлэн хүргэсэн' || e.status === 'Хүргэсэн'
                            : id === 'Хойшлуулсан' ?
                                e.status === 'Маргааш авах' || e.status === 'Хойшлуулсан'
                                : e.status === id)
                    } drivers={drivers} edit={edit} />
                </div>
            </div>
        </>
    )
}

export default OrderFiltered
