import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Navbar from '../../../Components/Navbar/Navbar'
import ReportContainerTable from '../../../Utills/Table/ReportContainerTable';
import axios from 'axios';
import styles from './ContainerReport.module.css'

const ContainerReport = ({ orders }) => {
  const [startValue, setStartValue] = useState(dayjs());
  const [endValue, setEndValue] = useState(dayjs());
  const [isStartCalendar, setIsStartCalendar] = useState(false);
  const [isEndCalendar, setIsEndCalendar] = useState(false);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [orders, setOrders] = useState([]);
  const [container, setContainer] = useState([]);

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  useEffect(() => {
    window.localStorage.setItem('side-id', 4);
    if (isLoading) {

      axios.get('https://turboapi.templateapi.xyz/container?user=' + userInfo._id)
        .then((e) => {
          setContainer(e.data.data);
          setIsLoading(false);
        })
        .catch((e) => alert(e));

      axios.get('https://turboapi.templateapi.xyz/post?user=' + userInfo._id)
        .then((e) => {
          setReports(e.data);
        })
        .catch((e) => alert(e));

      axios.get('https://turboapi.templateapi.xyz/back')
        .then((e) => {
          setIsLoading(false);
        })
        .catch((e) => alert(e));
    }
  }, [isLoading])

  // new dayjs(Date.now()) > startValue ? alert('date') : alert('false');
  return (
    <>
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <Navbar title={'Агуулахын тайлан'} />
      <div className={styles.container}>
        <div className='flex-space-between'>
          <div className='space-between'>
            <div style={{ width: '20vw', zIndex: 1 }}>
              {isEndCalendar ? (
                <div className='calendar-div'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                      <DemoItem>
                        <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                        <DateCalendar value={endValue} onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              ) : (
                <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
                  {/* End date: */}
                  Эхлэх хугацаа:
                  <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
                </label>
              )}
            </div>
            <div style={{ width: '20vw', zIndex: 1 }}>
              {isStartCalendar ? (
                <div className='calendar-div'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                      <DemoItem>
                        <p onClick={() => setIsStartCalendar(false)}>Hide</p>
                        <DateCalendar value={startValue} onChange={(newValue) => { setStartValue(newValue); setIsStartCalendar(false) }} />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              ) : (
                <label className='calendar-label' onClick={() => setIsStartCalendar(true)}>
                  {/* Start date: */}
                  Дуусах хугацаа:
                  <p className='calendar'>{startValue.format('YYYY-MM-DD')}</p>
                </label>
              )}
            </div>
          </div>
          <div className={styles.column}>
            <label>
              Барааны үлдэгдлээр:
              <select name="" id="">
                <option value="">Сонгох</option>
              </select>
            </label>
            {/* <button className='button'>Search</button> */}
          </div>
        </div>
        <br />
        {isLoading ? <div className="loader"></div> : <ReportContainerTable container={container}
          endDate={endValue} startDate={startValue} report={reports} orders={orders}
        />}
      </div>
    </>
  )
}

export default ContainerReport
