import React, { useEffect, useState } from 'react'
import styles from './Navbar.module.css'
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningModal from '../../Utills/Modal/WarningModal';

const Navbar = ({ title }) => {
  const [msgs, setMsgs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModal, setIsModal] = useState(true);

  const user = JSON.parse(window.localStorage.getItem('useridturbo'));
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      axios.get('https://turboapi.templateapi.xyz/msg')
        .then((e) => {
          setIsLoading(false);
          setMsgs(e.data.data);
        })
        .catch((e) => console.log(e));
    }
  }, [isLoading])

  if (isLoading) {
    return <div></div>
  }

  const log_out = () => {
    window.localStorage.removeItem('useridturbo');
    navigate("/");
    window.location.reload(false);
  }

  const closeModal = () => {
    setIsModal(false);
  }


  return (
    <>
      {isModal ? <WarningModal title={'Сайн байна уу? Манай систем нь шинэчлэгдсэн тул та Delivery.infitech.mn линкээр орон харилцагчийн эрхээрээ нэвтрэн орно уу. Мөн манайхаас харилцагч дэлгүүрүүд рүү заавар бичлэг явуулсан байгаа тул шинэ системийн заавартайгаа танилцана уу. Ойлгомжгүй болон асуух зүйл гарвал /90089080; 94107902/дугаарт холбогдно уу.Баярлалаа'} closeModal={closeModal} /> : null}
      <div className={styles.container}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p className={styles.title}>{title}</p>
          <p>Turbo Delivery V2</p>
        </div>
        <div className={styles.space_between}>
          <p style={{ color: '#838383' }}>{user.name}</p>
          <a href="https://old.turbodeliver.mn">Өмнөх хувилбар-луу шилжих</a>
          <div onClick={log_out} className={styles.log_out}>
            <IoIosLogOut color='red' size={25} />
            <p>Гарах</p>
          </div>
        </div>
      </div>
      <br />
      {msgs.length > 0 ? <div style={{ background: '#ffcd78' }} className={styles.container}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <WarningAmberIcon style={{ color: 'red' }} />
          <p>Танд мэдэгдэл ирсэн байна.</p>
        </div>
        <div>
          <button onClick={() => setIsModal(true)} style={{ color: 'orange', borderRadius: '20px', fontSize: '14px' }} className="button-white">
            Мэдэгдэл харах
          </button>
        </div>
      </div> : null}
    </>
  )
}

export default Navbar
