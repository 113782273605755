import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import { IoIosAddCircleOutline } from "react-icons/io";
import OrderItemDetialModal from '../Modal/DetialModal/OrderItemDetialModal';
import { LiaCommentsSolid } from "react-icons/lia";
import CommentModal from '../Modal/Uttils/CommentModal';
import { CiEdit } from "react-icons/ci";
import EditOrderModal from '../Modal/EditModal/EditOrderModal';
import axios from 'axios';

export default function OrderTable({ orders, drivers }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [isMore, setIsMore] = React.useState(false);
  const [orderItems, setOrderItems] = React.useState([]);
  const [comment, setComment] = React.useState('');
  const [commentModal, setCommentModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [edit, setEdit] = React.useState('');
  const [deleteId, setDelete] = React.useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeModal = () => {
    setIsMore(false);
    setCommentModal(false);
    setIsEdit(false);
  }

  const deleteOrder = () => {
    deleteId ?
      axios.delete('https://turboapi.templateapi.xyz/order/' + deleteId)
        .then((e) => window.location.reload(false))
        .catch((e) => console.log(e)) : console.log('object')
  }

  return (
    <>
      {commentModal ? <CommentModal closeModal={closeModal} text={comment} /> : <></>}
      {isEdit && edit ? <EditOrderModal id={edit} closeModal={closeModal} /> : null}
      {isMore ? <OrderItemDetialModal orders={orderItems} closeModal={closeModal} /> : <></>}
      <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: '#2c9961', color: '#fff' }}>
              <TableCell className='table-title' style={{ color: '#fff', width: '20px' }}></TableCell>
              <TableCell className='table-title' style={{ color: '#fff', width: '20px' }}>Id</TableCell>
              <TableCell className='table-title' style={{ color: '#fff', width: '170px' }}>Утас / Хаяг</TableCell>
              {/* <TableCell className='table-title' style={{ color: '#fff', width: '100px' }}>Харилцагч</TableCell> */}
              <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Төлөв</TableCell>
              <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Бүс</TableCell>
              <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Нийт дүн</TableCell>
              <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үүсгэсэн огноо</TableCell>
              <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үйлдэл дарсан огноо</TableCell>
              <TableCell className='table-title' style={{ color: '#fff', width: '250px' }} align="center">Жолооч</TableCell>
              <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үйлдэл</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <>
                  <TableRow className='hover' key={index}>
                    <TableCell className='table-item' align="left">
                      <IoIosAddCircleOutline cursor={'pointer'} size={20}
                        onClick={() => {
                          setOrderItems(row.order)
                            ; setIsMore(true)
                        }}
                      />
                    </TableCell>
                    <TableCell className='table-item' align="left">{index + 1}</TableCell>
                    <TableCell
                      className={
                        row.status === 'Хойшлуулсан' || row.status === 'Маргааш авах' ? 'isFuture table-item' : 'table-item'
                      }
                      component="th" scope="row">
                      {row.phone} <br /> {row.address}
                    </TableCell>
                    {/* <TableCell className='table-item' align="center">{user.filter((e) => e._id.includes(row.user)).map((e) => (
                      e.name
                    ))}</TableCell> */}
                    <TableCell className='table-item' align="center">
                      <div className='line-start'>
                        {row.comment ? <LiaCommentsSolid onClick={() => {
                          setCommentModal(true);
                          setComment(row.comment);
                        }}
                          size={20} color='red' cursor={'pointer'} /> : <></>}
                        <p className={row.status === 'Хүлээгдэж байна' ? 'green-border' :
                          row.status === 'Жолооч авсан' ? 'blue-border' :
                            row.status === 'Хүргэсэн' ? 'full-green' :
                              row.status === 'Хаягаар очсон' ? 'full-red' :
                                row.status === 'Маргааш авах' ? 'full-blue' :
                                  row.status === 'Хойшлуулсан' ? 'full-yellow'
                                    : row.status === 'Буцаагдсан' ? 'red-border'
                                      : 'back'
                        }>
                          {row.status}
                        </p>
                      </div>
                      {row.isBack ? <p className='back'>Буцаалтыг хүлээж авсан</p> : null}
                    </TableCell>
                    <TableCell className='table-item' align="center">{row.area}</TableCell>
                    <TableCell className='table-item' align="center">{new Intl.NumberFormat('en-Us').format(row.price)}</TableCell>
                    <TableCell className='table-item' style={{ width: '100px' }} align="center">{
                      dayjs(row.createdAt).format('YYYY-MM-DD')
                    }</TableCell>
                    <TableCell className='table-item' style={{ width: '100px' }} align="center">{
                      dayjs(row.orderDate).subtract(8, 'hour').format('YYYY-MM-DD')
                    }</TableCell>
                    <TableCell className='table-item' align="center">
                      {drivers.filter((e) => row.driver ? e._id.includes(row.driver) : '').map((e) => (
                        <p>{e.name}</p>
                      ))}
                    </TableCell>
                    <TableCell className='table-item' align="center">
                      {row.status === 'Хүлээгдэж байна' ? <CiEdit
                        onClick={() => {
                          setEdit(row._id);
                          setIsEdit(true);
                        }}
                        size={30} color='blue'
                      /> : null}
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
