import React, { useEffect, useState } from 'react'
import styles from './Order.module.css'
import Navbar from '../../Components/Navbar/Navbar';
import axios from 'axios';
import ExportExcel from '../../excelexport';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import OrderItemListTable from '../../Utills/Table/OrderItemListTable';

const OrderItems = ({ orders }) => {
    const [isModal, setIsModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editId, setEditId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    // const [orders, setOrders] = useState([]);
    const [drivers, setDriver] = useState([]);
    const [shops, setShops] = useState([]);
    const [status, setStatus] = useState('');
    const [excelData, setExcelData] = useState([{ утас: '', хаяг: '', төлөв: '', 'нийт дүн': '', огноо: '', жолооч: '' }])
    const [isEndCalendar, setIsEndCalendar] = useState(false);
    const [endValue, setEndValue] = useState(dayjs());
    const [phone, setPhone] = useState('');
    const [itemName, setItemName] = useState('');
    const [isSearch, setSearch] = useState(false);
    const [isStartCalendar, setIsStartCalendar] = useState(false);
    const [startValue, setStartValue] = useState(dayjs());
    const [isCreated, setIsCreated] = useState(false);

    // const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

    const closeModal = () => {
        setIsModal(false);
        setEditModal(false);
    }

    useEffect(() => {
        window.localStorage.setItem('side-id', 1);
        if (isLoading) {
            axios.get('https://turboapi.templateapi.xyz/user?sort=-createdAt&role=driver')
                .then((e) => {
                    setDriver(e.data.data);
                })
                .catch((e) => console.log(e));

            // axios.get('https://turboapi.templateapi.xyz/order?sort=-createdAt&user=' + userInfo._id)
            //     .then((order) => {
            //         setOrders(order.data.data);
            //         setIsLoading(false);
            //     })
            //     .catch((e) => console.log(e));

            // axios.get('https://turboapi.templateapi.xyz/user?role=user')
            //     .then((e) => {
            //         setUsers(e.data.data);
            //         setIsLoading(false);
            //     });

            axios.get('https://turboapi.templateapi.xyz/shop')
                .then((e) => {
                    setShops(e.data.data);
                    setIsLoading(false);
                });
        }

        if (!isLoading) {
            const exceldatas = orders
                .filter((e) => isSearch ?
                    (
                        (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(endValue, 'date') ||
                            dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isBefore(endValue))
                        &&
                        (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(startValue, 'date') ||
                            dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isAfter(startValue))
                    )
                    : e)
                .flatMap((order) => {
                    return order.order.map(item => ({
                        утас: parseInt(order.phone),
                        хаяг: order.address,
                        // дэлгүүр: shops.find((shop) => shop.user === order.user)?.name,
                        төлөв: order.status,
                        'нийт дүн': order.price,
                        'Үүсгэсэн огноо': dayjs(order.createdAt).format('YYYY-MM-DD'),
                        'Төлөв өөрчилсөн огноо': dayjs(order.orderDate).subtract(8, 'hour').format('YYYY-MM-DD'),
                        жолооч: drivers.find((catchdriver) => catchdriver._id === order.driver)?.name,
                        'бараа нэр': item.name,
                        'бараа дүн': item.price,
                        'бараа тоо': item.unit,
                        'хөнгөлөлт': parseInt(order.discount)
                    })
                    )
                }
                )
            setExcelData(exceldatas);
        }


    }, [isLoading, drivers, orders, isSearch, isCreated, startValue, endValue])

    const edit = ({ id }) => {
        setEditId(id);
        setEditModal(true);
    }

    return (
        <>
            {!isModal ? <Navbar title={'Захиалгын бараанууд'} /> : <></>}
            {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
            <div className={styles.container}>
                <div className='line-start'>
                    <label>
                        Төлвөөр шүүх:
                        <select name="" id="" onChange={(e) => setStatus(e.target.value)}>
                            <option value=''>Бүгд</option>
                            <option value='Хүлээгдэж байна'>Хүлээгдэж байна</option>
                            <option value='Буцаагдсан'>Буцаагдсан</option>
                            <option value='Жолооч авсан'>Жолооч авсан</option>
                            <option value='Маргааш авах'>Маргааш авах</option>
                            <option value='Хойшлуулсан'>Хойшлуулсан</option>
                            <option value='Хүргэсэн'>Хүргэсэн</option>
                            <option value='Хаягаар очсон'>Хаягаар очсон</option>
                            <option value='Хэсэгчлэн хүргэсэн'>Хэсэгчлэн хүргэсэн</option>
                        </select>
                    </label>
                    <ExportExcel fileName={'name'} excelData={excelData} />
                </div>
                <br />
                <div className="line-start">
                    <label>
                        Дугаараар хайх:
                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} name="" id="" />
                    </label>
                    <label htmlFor="">
                        Барааны нэрээр хайх:
                        <input type="text" value={itemName} onChange={(e) => setItemName(e.target.value)} name="" id="" />
                    </label>
                </div>
                <br />
                <div className="line-start">
                    {isStartCalendar ? (
                        <div className="calendar-div" style={{ zIndex: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                    <DemoItem>
                                        <p onClick={() => setIsStartCalendar(false)}>Hide</p>
                                        <DateCalendar value={startValue}
                                            onChange={(newValue) => { setStartValue(newValue); setIsStartCalendar(false) }} />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    ) : (
                        <label className='calendar-label' onClick={() => setIsStartCalendar(true)}>
                            {/* End date: */}
                            Эхлэх хугацаа:
                            <p className='calendar'>{startValue.format('YYYY-MM-DD')}</p>
                        </label>
                    )}
                    {isEndCalendar ? (
                        <div className="calendar-div" style={{ zIndex: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                    <DemoItem>
                                        <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                                        <DateCalendar value={endValue}
                                            onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    ) : (
                        <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
                            {/* End date: */}
                            Дуусах хугацаа:
                            <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
                        </label>
                    )}

                    {isSearch ? <button style={{ fontSize: '14px', padding: '5px 7px' }} className='button-white'
                        onClick={() => setSearch(false)}
                    >
                        Бүгдийг харах
                    </button> :
                        <button style={{ fontSize: '14px', padding: '5px 25px' }} className='button'
                            onClick={() => setSearch(true)}
                        >
                            Хайх
                        </button>}
                    {isCreated && isSearch ? <button style={{ fontSize: '14px', padding: '5px 7px' }} className='button-white'
                        onClick={() => setIsCreated(false)}
                    >
                        Үйлдэл дарсан огноо
                    </button> : isSearch &&
                    <button style={{ fontSize: '14px', padding: '5px 25px' }} className='button'
                        onClick={() => setIsCreated(true)}
                    >
                        Үүсгэсэн огноо
                    </button>}
                </div>
                <br />
                <div>
                    <OrderItemListTable orders={orders
                        .filter((e) => phone ? e.phone.includes(phone) : e)
                        .filter((e) => isSearch ?
                            (
                                (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(endValue, 'date') ||
                                    dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isBefore(endValue))
                                &&
                                (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(startValue, 'date') ||
                                    dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isAfter(startValue))
                            )
                            : e)
                        .filter((e) => status ? e.status === status : e)
                    }
                        itemname={itemName}
                        drivers={drivers} edit={edit}
                    />
                </div>
            </div>
        </>
    )
}

export default OrderItems
