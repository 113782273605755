import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import ItemsEditModal from '../Modal/EditModal/ItemEditModal';
import { CiEdit } from "react-icons/ci";
import axios from 'axios';

export default function ItemsTable({ posts, search }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
  const [isModal, setModal] = React.useState(false);
  const [id, setId] = React.useState('');

  const closeModal = () => {
    setModal(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
  };

  // const deleteItemm = () => {
  //   axios.delete('https://turboapi.templateapi.xyz/post')
  // }

  return (
    <>
      {isModal ? <ItemsEditModal id={id} closeModal={closeModal} /> : <></>}
      <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: '#2c9961', color: '#fff' }}>
              <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
              {/* <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Харилцагч</TableCell> */}
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Зураг</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="left">Барааны нэр</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Дэлгүүр</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="left">Барааны үнэ</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Барааны код</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Барааны бүлэг</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Бүртгэсэн огноо</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {posts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter((e) => {
                return e.title && search !== '' ? e.title.toLowerCase().includes(search) : e;
              }).map((row, index) => (
                <TableRow className='hover'
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  {/* <TableCell align="center">{users
                  .filter((e) => e._id.includes(row.user))
                  .map((e) => (
                    <p>{e.name}</p>
                  ))}
                </TableCell> */}
                  <TableCell align="center"><img
                    src={'https://turboapi.templateapi.xyz/' + row.cover} alt=""
                  /></TableCell>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell align="center">{row.shop}</TableCell>
                  <TableCell align="center">{Intl.NumberFormat('en-us').format(row.price)}</TableCell>
                  <TableCell align="center">{row._id}</TableCell>
                  <TableCell align="center">{row.category}</TableCell>
                  <TableCell align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                  <TableCell align="center">
                    {/* <div>
                      <CiEdit
                        onClick={() => { setModal(true); setId(row._id) }}
                        size={30} style={{ cursor: 'pointer' }} color='blue'
                      />
                    </div> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={posts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}