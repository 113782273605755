import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { CiEdit } from "react-icons/ci";
import DriverOrderStatusChangeModal from '../EditModal/DriverOrderStatusChangeModal';

const DriverOrderDetialModal = ({ id, closeModal }) => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editModal, setEditModal] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [orderStatus, setOrderStatus] = useState('');

    useEffect(() => {
        if (isLoading) {
            // alert(id);
            axios.get('https://turboapi.templateapi.xyz/order?driver=' + id)
                .then((e) => {
                    setOrders(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e));
        }
    }, [isLoading])

    const closeEditModal = () => {
        setEditModal(false);
    }
    return (
        <>
            {editModal && orderId !== '' ? <DriverOrderStatusChangeModal orderStatus={orderStatus} phone={phone} address={address} id={orderId} closeModal={closeEditModal} /> :
                isLoading ? <div style={{ position: 'absolute', width: '100vw', left: 0, top: 0, height: '100vh', zIndex: 110 }} className="blur_container"><div className="loader"></div></div> :
                    <div className='blur_container' style={{ position: 'absolute', width: '100vw', left: 0, top: 0, height: '150vh', zIndex: 110 }}>
                        <div className='modal'>
                            <div className="two-line">
                                <h2>Жолоочийн хүргэлтүүд</h2>
                                <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                            </div>
                            <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
                                <Table sx={{ minWidth: '40vw', maxWidth: '50vw' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                                            <TableCell align="center" style={{ color: '#fff', fontSize: 16 }}>Хаяг / Утас</TableCell>
                                            <TableCell align="center" style={{ color: '#fff', fontSize: 16 }}>Төлөв</TableCell>
                                            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders
                                            .filter((e) => dayjs(e.updatedAt).isAfter(dayjs(Date.now()).subtract(1, 'day').format('YYYY-MM-DD')))
                                            // .filter((e) => dayjs(e.updatedAt).isAfter('2024-02-14T05:37:32.340Z'))
                                            .map((row, index) => (
                                                <TableRow
                                                    className='hover'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    key={index}
                                                >
                                                    <TableCell align="center">
                                                        {row.address} / {row.phone}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.status}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {/* uildel */}
                                                        {/* <input style={{visibility: 'hidden'}} type="number" name='staticUnit' id="" /> */}
                                                        <CiEdit onClick={() => {
                                                            setEditModal(true);
                                                            setOrderId(row._id);
                                                            setAddress(row.address);
                                                            setPhone(row.phone);
                                                            setOrderStatus(row.status);
                                                        }} size={30} color='orange' />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
            }
        </>
    )
}

export default DriverOrderDetialModal